import './App.css';
import Layout from "./layouts/layout";
import Home from './pages/Home'
import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";

function App() {
    const [lang, setLang] = useState(localStorage.getItem('deckmans_website_lang') || 'en')
    const [queryParams, setQueryParams] = useState(null)
    const pageProps = { lang, setLang }

    useEffect(() => {
        // Parse query string when component mounts
        const params = new URLSearchParams(window.location.search);
        const paramsObject = {};
        for (const [key, value] of params) {
            paramsObject[key] = value;
        }
        setQueryParams(paramsObject);
    }, []);

    const page = (queryParams && queryParams.page) ?? null
    return (
        <Layout page={page} pageProps={ pageProps } />
    );
}

export default App;
