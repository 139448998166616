import React, {useEffect, useState} from 'react'
import { t } from "../utils/translate/translate"
import Menu1 from '../images/h16x.jpg'
import Menu2 from '../images/5iwvapgY.jpeg'
import Menu3 from '../images/Q04Sc43o.jpeg'
import Menu4 from '../images/KtGd3Ebs.jpeg'

const ContactUs = props => {
    const url = 'https://deckmans.com/api/email_api.php'
    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    })
    const [success, setSuccess] = useState(false)
    const [failed, setFailed] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setForm({ ...form, [name]: value })
    };
    const handleSubmit = async () => {
        if (isSubmitting) return // Prevent multiple submissions

        setIsSubmitting(true)
        setSuccess(false)
        setFailed(false)

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(form),
            })

            const data = await response.json()
            console.log(data) // Log response data

            // Clear form inputs after successful submission
            setForm({
                name: '',
                email: '',
                phone: '',
                message: ''
            })

            setSuccess(true)

        } catch (error) {
            console.error('Error submitting form:', error)
            setFailed(true)
        } finally {
            setIsSubmitting(false); // Reset submitting state
        }
    }
    useEffect(() => {
        const pageElement = document.getElementById('page');
        if (pageElement) {
            pageElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [])
    return (
        <div className="container">
            <div className="row my-2">
                <div className="col-sm py-5 px-0 text-center bg-info menu_box menu_box_3">
                    <span className="py-2 my-5 bg-deckmans-green-75 text-white fs-4 d-block w-100">
                        <a href="?page=chef" className='text-white text-decoration-none'>
                            { t('press.menu.chef') }
                        </a>
                    </span>
                </div>
                <div className="col-sm py-5 px-0 text-center bg-info menu_box menu_box_2">
                    <span className="py-2 my-5 bg-deckmans-green-75 text-white fs-4 d-block w-100">
                        <a href="?page=press" className='text-white text-decoration-none'>
                            { t('about.menu.press') }
                        </a>
                    </span>
                </div>
                <div className="col-sm py-5 px-0 text-center bg-info menu_box menu_box_5">
                    <span className="py-2 my-5 bg-deckmans-green-75 bg-opacity-25 text-white fs-4 d-block w-100">
                        <a href="?page=gallery" className='text-white text-decoration-none'>
                            { t('about.menu.gallery') }
                        </a>
                    </span>
                </div>
            </div>
            <div id="page" className="row my-2">
                <div className="col py-5 px-0 bg-deckmans-green text-center">
                    <p className='text-white font-text-normal'>{ t('contact.form.title') }</p>
                   <form className='w-50 mx-auto'>
                       <div className="mb-3">
                           <input
                               className="form-control"
                               type="text"
                               id="name"
                               name="name"
                               placeholder={ t('contact.form.name') }
                               onChange={handleInputChange}
                               value={form.name}
                               disabled={isSubmitting}
                           />
                       </div>
                       <div className="mb-3">
                           <input
                               type="email"
                               className="form-control"
                               id="email"
                               name="email"
                               placeholder={ t('contact.form.email') }
                               onChange={handleInputChange}
                               value={form.email}
                               disabled={isSubmitting}
                           />
                       </div>
                       <div className="mb-3">
                           <input
                               type="text"
                               className="form-control"
                               id="phone"
                               name="phone"
                               placeholder={ t('contact.form.phone') }
                               onChange={handleInputChange}
                               value={form.phone}
                               disabled={isSubmitting}
                           />
                       </div>
                       <div className="mb-3">
                           <textarea
                               className='form-control'
                               rows='10' id="message"
                               placeholder={t('contact.form.message')}
                               id="message"
                               name="message"
                               onChange={handleInputChange}
                               value={form.message}
                               disabled={isSubmitting}
                           />
                       </div>
                       <div className="mb-3 d-grid gap-2">
                           <button
                               type="button"
                               className="btn bg-white"
                               onClick={handleSubmit}
                               disabled={isSubmitting}
                           >
                               {isSubmitting ? 'Enviando...' : t('contact.form.send')}
                           </button>
                       </div>
                       {success && (
                           <div className="mb-3 d-grid gap-2">
                               <span className="badge text-bg-success">
                                   {t('contact.form.success')}
                               </span>
                           </div>
                       )}

                       {/* Show failure message */}
                       {failed && (
                           <div className="mb-3 d-grid gap-2">
                               <span className="badge text-bg-danger">
                                   {t('contact.form.failure')}
                               </span>
                           </div>
                       )}
                   </form>
                    <p className='text-white font-text-normal' dangerouslySetInnerHTML={{ __html: t('contact.form.footer') }} />
                </div>
            </div>
        </div>
    )
}

export default ContactUs