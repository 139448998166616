const en = {
    test: 'Test',
    // Footer
    footer: {
        title: 'Menu',
        subtitle: 'We cook with fresh and seasonal ingredients. <br/> Get to know today\'s menu.',
        schedule: {
            title: 'Schedule',
            subtitle: 'Thursday - Monday <br />1:00 pm - 7:30 pm PST',
        },
        location: {
            title: 'Location',
            subtitle: 'Ensenada - Tecate Highway Km. 85.5 <br />San Antonio de Las Minas. 22766 <br />Valle de Guadalupe, B.C. Mexico'
        },
        phone: {
            title: 'Phone',
            subtitle: 'USA-619-721-4820<br />MX-646-188-3960'
        },
        privacy: 'Notice of Privacy (spanish)'
    },
    // Home menu
    home: {
        menu: {
            reservations: 'Reservations',
            gift_certificates: 'Gift Certificates',
            about_us: 'About Us',
            recommendations: 'Recommendations',
        }
    },
    // About
    about: {
        menu: {
            chef: 'Chef Drew Deckman',
            press: 'Press',
            gallery: 'Gallery',
            contact: 'Contact Us',
        },
        page: {
            title: 'About Us',
            subtitle: '!Welcome to Deckmans En El Mogor!',
            text: 'Our goal is to share with you the sustainable experience that is #projectmogor Unless\n' +
                'Unless otherwise indicated, our wines are from Baja California, mainly from Cavas del Mogor and\n' +
                'neighboring wineries within a 10 km radius.<br><br>' +
                'We strive to be as sustainable as possible and therefore do not offer bottled waters\n' +
                'commercials or soft drinks. We make Kombucha and use a potable water system that allows us\n' +
                'gasify, filter and use sterilized bottles over and over again. We recycle our wastewater to\n' +
                'We use irrigation and compost our organic waste as well as recycle solid waste to\n' +
                'our maximum capabilities.<br>' +
                'All our food is prepared to order, fresh, maximizing freshness and quality.<br>' +
                'Please inform us of any dietary restrictions and/or allergies. We are more than happy to\n' +
                'meet your special requirements.<br><br>' +
                'Thank you for being part of the sustainable table that is Deckman\'s in Mogor.<br>' +
                'Remember, you vote with your fork every day! Choose wisely! This is Slow Food not fast food.<br><br>' +
                'Enjoy.<br>' +
                'Drew and Paulina Deckman'
        }
    },
    // Chef
    chef: {
        menu: {
            press: 'Press',
            gallery: 'Gallery',
            contact: 'Contact Us',
        },
        page: {
            title: 'Chef Drew Deckman',
            subtitle: 'About the chef and the restaurant',
            text: 'DREW DECKMAN | CHEF & CO-OWNER<br />' +
                '31ThirtyOne by Deckman’s (San Diego, CA) <a href="https://the3131.com" target="_blank">the3131.com</a><br />' +
                'Deckman’s en el Mogor (Valle de Guadalupe, B.C. México) <a href="https://deckmans.com" target="_blank">deckmans.com</a><br />' +
                'Conchas de Piedra (Valle de Guadalupe, B.C. México) <a href="https://conchasdepiedra.com" target="_blank">conchasdepiedra.com</a><br />' +
                'Enso Omakase (Valle de Guadalupe, B.C. México) <a href="https://ensomakase.com" target="_blank">ensomakase.com</a><br />' +
                '<br />' +
                'Drew Deckman is a prominent advocate for ethical farming and the slow food movement in Mexico, committed to embedding principles of environmental sustainability in every aspect of the dining experience at the zero-kilometer/ responsable  Deckman’s restaurant group.' +
                ' <br /><br />' +
                'This summer, Drew and his wife, Paulina Deckman, will debut their first restaurant in the United States, 31THIRTYONE in San Diego, CA. This cause-driven concept aims to expand Deckman’s earth-to-table mission, prioritizing sustainable sourcing across all operations and pledging 1% of monthly revenue to support farmers in implementing carbon farming projects in collaboration with Zero Foodprint.' +
                ' <br /><br />' +
                'In 2024, with the arrival of the Michelin Guide in Mexico, Deckman’s en el Mogor earned the prestigious Green Star in the Sustainability Category, while Conchas de Piedra received both the Green Star and the coveted Red Star.' +
                ' <br /><br />' +
                '—Deckman’s en el Mogor, Conchas de Piedra, and Ensō Omakase—have garnered accolades from prestigious culinary organizations and featured in esteemed publications like The New York Times, Wall Street Journal, Food & Wine, and Bon Appétit, etc. significantly enhancing the profile of Mexico’s premier wine region.' +
                ' <br /><br />' +
                'Before settling in Baja, Drew spent over a decade honing his culinary craft in Europe, learning from masters such as Paul Bocuse, Jacques Maximin, and his mentor Madeleine Kamman. His leadership at Restaurant Vitus earned him a coveted MICHELIN Star in 2000, and he was recognized as a Rising-Star Chef during his tenure at the Four Seasons Berlin in 2003.' +
                ' <br /><br />' +
                'Drew will also star as the host and executive producer of Ingrediente Mexico, a new docuseries premiering on Amazon Prime Video this summer. This captivating series follows renowned chefs, producers, and artisans as they embark on a genuine culinary and cultural journey to explore indigenous ingredients from around the world.' +
                '<br /><br />' +
                'Beyond his restaurants, Drew passionately applies his ethical values and extensive knowledge of food systems in his roles as Regional Governor for Slow Food International and board member of the Sustainable Seafood Foundation and Meals on Wheels San Diego. He has collaborated with various NGOs, including Smart Fish, Pro-Natura, EDF, and WWF, and is an active member of Slow Food USA and Mexico, as well as the Blue Ribbon Task Force by Seafood Watch at the Monterey Bay Aquarium.',
            membership_links: 'MEMBERSHIPS AND AFFILIATIONS',
        }
    },
    // Press
    press: {
        menu: {
            chef: 'Chef Drew',
            gallery: 'Gallery',
            contact: 'Contact Us',
            about_us: 'About Us'
        },
        page: {
            title: 'Press',
            subtitle: 'Awards and honours',
            articles: 'Articles',
        }
    },
    // Gallery
    gallery: {
        menu: {
            chef: 'Chef Drew',
            press: 'Press',
            contact: 'Contact Us',
        },
        page: {
            title: 'Gallery',
        }
    },
    // Contact Us
    contact: {
        header: 'About us - Contact us',
        menu: {
            chef: 'Chef Drew',
            press: 'Press',
            gallery: 'Gallery'
        },
        form: {
            title: 'We appreciate your comments that make us grow and improve for you!',
            name: 'Name',
            email: 'Email',
            phone: 'Phone',
            message: 'Message',
            send: 'Send message',
            footer: '*All fields are required. <br /><br />This form is NOT for reservations, to reserve <a style="color: white;" href="?page=reservations">click here<a>.<br /><br />Once we receive your message, we will respond as soon as possible.<br /><br />Internships and full-time jobs send your CV to <a style="color: white;" href="mailto:paulina@deckmans.com">paulina@deckmans.com</a>',
            success: 'Your message has been sent sucessfully.',
            failed: 'Your message couldn\'t be sent out. Please try again later.'
        }
    },
    // Recommendations
    recommendations: {
        header: 'About us - Recommendations',
        menu: {
            chef: 'Chef Drew',
            press: 'Press',
            gallery: 'Gallery'
        },
        page: {
            title: 'Recommendations',
            subtitle: 'Deckman\'s more than a culinary concept, it is a multisensory experience',
            text: 'Being a restaurant designed entirely outdoors, we are 100% in contact with nature and exposed to our environment, we have listed some suggestions to make your visit much more integrated into the context:',
            bullets: [
                'We suggest bringing a light jacket, even in summer since we are in a Mediterranean area where we experience low temperatures in the evening.',
                'If you are not coming here by driving, we suggest scheduling a private transportation with a local provider.',
                'We do not have a regular public transportation system in our area.',
                'We suggest closed/comfortable shoes.',
                'Check the weather on your reserved date.',
                'The suggested dress code is casual.',
            ]
        }
    },
    // Reservations
    reservations: {
        header: 'Reservations',
        menu: {
            gift_certificates: 'Gift Certificates',
            about_us: 'About Us',
            recommendations: 'Recommendations',
        },
        page: {
            title: 'Reservations',
            subtitle: '',
            text: 'For online reservations of up to 6 people, you can make it through OpenTable',
            text_2: 'It is recommended to make only one reservation per group. In case multiple reservations are detected for the same groups, they will be automatically canceled by the system.',
            text_3: 'For direct reservations of up to 5 people, contact us by Whatsapp',
            text_4: 'For groups of 6 or more people, call the restaurant directly',
            bullets: [
                'It is highly recommended to book at least 2 to 3 weeks in advance.',
                'Daily service from 1:00 pm - 7:30 pm (UTC-8) Closed Tuesday and Wednesday.',
                'We accept payments in cash, Visa, Mastercard and American Express.'
            ]
        }
    }
}

export default en