import React, { useState, useEffect } from 'react'
import { t } from "../utils/translate/translate"
import Menu1 from '../images/h16x.jpg'
import Menu2 from '../images/5iwvapgY.jpeg'
import Menu3 from '../images/Q04Sc43o.jpeg'
import Menu4 from '../images/KtGd3Ebs.jpeg'

const Gallery = props => {
    const [imageUrls, setImageUrls] = useState([])
    const [selectedImage, setSelectedImage] = useState(null)
    const url = 'https://deckmans.com/api/gallery_images.php'
    useEffect(() => {
        // Fetch image URLs from a third-party service
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setImageUrls(data.urls) // Assuming 'urls' is an array of image URLs in the response
            })
            .catch(error => {
                console.error('Error fetching image URLs:', error)
            })
    }, [])
    useEffect(() => {
        const pageElement = document.getElementById('page');
        if (pageElement) {
            pageElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [])
    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl)
    }
    return (
        <div className="container">
            <div className="row my-2">
                <div className="col-sm py-5 px-0 text-center bg-info menu_box menu_box_3">
                    <span className="py-2 my-5 bg-deckmans-green-75 text-white fs-4 d-block w-100">
                        <a href="?page=chef" className='text-white text-decoration-none'>
                            { t('gallery.menu.chef') }
                        </a>
                    </span>
                </div>
                <div className="col-sm py-5 px-0 text-center bg-info menu_box menu_box_2">
                    <span className="py-2 my-5 bg-deckmans-green-75 bg-opacity-25 text-white fs-4 d-block w-100">
                        <a href="?page=press" className='text-white text-decoration-none'>
                            { t('gallery.menu.press') }
                        </a>
                    </span>
                </div>
                <div className="col-sm py-5 px-0 text-center bg-info menu_box menu_box_6">
                    <span className="py-2 my-5 bg-deckmans-green-75 bg-opacity-25 text-white fs-4 d-block w-100">
                        <a href="?page=contact" className='text-white text-decoration-none'>
                            { t('gallery.menu.contact') }
                        </a>
                    </span>
                </div>
            </div>
            <div id="page" className="row my-2">
                <div className="col py-5 px-0 text-left">
                    <h3>{ t('gallery.page.title') }</h3>
                    {/*  Gallery cards here with bootstrap 5.3  */}
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        {/* Render image cards */}
                        {imageUrls.map((imageUrl, index) => (
                            <div key={index} className="col">
                                <div className="card">
                                    <img
                                        src={imageUrl}
                                        className="card-img-top"
                                        alt={`Image ${index + 1}`}
                                        onClick={() => handleImageClick(imageUrl)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* Modal for displaying selected image */}
            {selectedImage && (
                <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-body">
                                <img src={selectedImage} className="img-fluid" alt="Selected Image" />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setSelectedImage(null)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Gallery