const es = {
    test: 'Prueba',
    // Footer
    footer: {
        title: 'Menú',
        subtitle: 'Cocinamos con los ingredientes frescos y de temporada. <br /> Conoce el menú de hoy.',
        schedule: {
            title: 'Horario',
            subtitle: 'Jueves - Lunes <br />1:00 pm - 7:30 pm PST',
        },
        location: {
            title: 'Ubicación',
            subtitle: 'Carretera Ensenada - Tecate Km. 85.5 <br>San Antonio de Las Minas. 22766 <br />Valle de Guadalupe, B.C. México'
        },
        phone: {
            title: 'Teléfono',
            subtitle: 'USA-619-721-4820<br />MX-646-188-3960'
        },
        privacy: 'Aviso de Privacidad'
    },
    // Home menu
    home: {
        menu: {
            reservations: 'Reservaciones',
            gift_certificates: 'Certificados de Regalo',
            about_us: 'Nosotros',
            recommendations: 'Recomendaciones',
        }
    },
    // About
    about: {
       menu: {
           chef: 'Chef Drew Deckman',
           press: 'Prensa',
           gallery: 'Galería',
           contact: 'Contáctanos',
       },
        page: {
           title: 'Nosotros',
            subtitle: '!Bienvenido a Deckmans En El Mogor',
            text: 'Nuestro objetivo es compartir con ustedes la experiencia sostenible que es #proyectomogor A menos\n' +
                'que se indique lo contrario, nuestros vinos son de Baja California, principalmente de Cavas del Mogor y\n' +
                'vinícolas vecinas a 10 km a la redonda.<br><br>' +
                'Nos esforzamos por ser lo más sostenibles posible y, por lo tanto, no ofrecemos aguas embotelladas\n' +
                'comerciales o refrescos. Hacemos Kombucha y utilizamos un sistema de agua potable que nos permite\n' +
                'gasificar, filtrar y usar botellas esterilizadas una y otra vez. Reciclamos nuestras aguas residuales para\n' +
                'uso de riego y compostamos nuestros residuos orgánicos así como reciclamos los desechos sólidos a\n' +
                'nuestras máximas capacidades.<br><br>' +
                'Toda nuestra comida está preparada a pedido, en el momento, maximizando la frescura y la calidad.<br><br>' +
                'Por favor comuníquenos de cualquier restricción alimenticia y/o alergias. Estamos más que felices de\n' +
                'cumplir con sus requerimientos especiales.<Br><br>' +
                'Gracias por ser parte de la mesa sustentable que es Deckman’s en el Mogor.\n' +
                'Recuerda, ¡Diariamente votas con tu tenedor! ¡Elige sabiamente! Esto es Slow Food no comida rápida.<br><br>' +
                'Buen Provecho.<br>' +
                'Drew y Paulina Deckman'
        }
    },
    // Chef
    chef: {
      menu: {
          press: 'Prensa',
          gallery: 'Galería',
          contact: 'Contáctanos',
      },
        page: {
            title: 'Drew Deckman',
            subtitle: 'Sobre el chef y el restaurante',
            text: 'CHEF Y COPROPIETARIO, DECKMAN’S RESTAURANT GROUP<br />' +
                '31ThirtyOne by Deckman’s (San Diego, CA) <a href="https://the3131.com" target="_blank">the3131.com</a><br />' +
                'Deckman’s en el Mogor (Valle de Guadalupe, B.C. México) <a href="https://deckmans.com" target="_blank">deckmans.com</a><br />' +
                'Conchas de Piedra (Valle de Guadalupe, B.C. México) <a href="https://conchasdepiedra.com" target="_blank">conchasdepiedra.com</a><br />' +
                'Enso Omakase (Valle de Guadalupe, B.C. México) <a href="https://ensomakase.com" target="_blank">ensomakase.com</a><br />' +
                '<br />' +
                'Drew Deckman es una figura destacada en la promoción de la agricultura ética y está profundamente comprometido con el movimiento Slow Food. Su misión es integrar principios de sostenibilidad ambiental en cada aspecto de sus experiencias culinarias. Junto a su esposa y socia, Paulina Deckman, han creado restaurantes responsables y de kilómetro cero en el Valle de Guadalupe, México, entre los que se encuentran Conchas de Piedra (Estrella MICHELIN, Estrella Verde MICHELIN), Deckman’s en el Mogor (Estrella Verde MICHELIN, 50Best Pasado y Futuro América Latina 2021) y el exclusivo Ensō Omakase, dirigido por el chef Rob Ruiz.' +
                ' <br /><br />' +
                'En agosto de 2024, Deckman’s Restaurant Group inauguró su primer restaurante en Estados Unidos, 31ThirtyOne en San Diego, CA. Este concepto se fundamenta en la misión del Chef Deckman de revolucionar la cultura alimentaria hiperlocal, priorizando la obtención de ingredientes responsables y de temporada. Además, se compromete a destinar el 1% de los ingresos mensuales para ayudar a los agricultores a implementar proyectos de agricultura de carbono en colaboración con Zero Foodprint.' +
                ' <br /><br />' +
                'Antes de establecerse en Baja, Drew acumuló más de una década de experiencia cocinando en Europa junto a maestros como Paul Bocuse, Jacques Maximin y su mentora Madeleine Kamman. Fue galardonado con una prestigiosa Estrella MICHELIN por su liderazgo en el Restaurante Vitus en 2000 (Alemania) y recibió el reconocimiento de Chef Revelación en 2003 durante su tiempo en el Four Seasons Berlín.' +
                ' <br /><br />' +
                'Fuera de sus restaurantes, Drew es el protagonista y productor ejecutivo de Ingrediente Mexico, una nueva docuserie que se proyectará internacionalmente en Amazon Prime Video. Esta serie resalta a los principales chefs, productores y artesanos de México en una auténtica búsqueda culinaria y cultural para aprender sobre ingredientes de todo el mundo.' +
                ' <br /><br />' +
                'El Chef Deckman aplica su apasionada ética y su conocimiento de los sistemas alimentarios en su papel como Gobernador Regional de Slow Food International y miembro de la junta de Sustainable Seafood Foundation y Meals on Wheels San Diego. Ha colaborado con diversas ONG, como Smart Fish, Pro-Natura, EDF y WWF, y es un miembro activo de Slow Food USA y México.' +
                ' <br /><br />',
            membership_links: 'Membresias y Afiliciaciones',
        }
    },
    // Press
    press: {
        menu: {
            chef: 'Chef Drew',
            gallery: 'Galería',
            contact: 'Contáctanos',
            about_us: 'Nosotros'
        },
        page: {
            title: 'Prensa',
            subtitle: 'Premios & Reconocimientos',
            articles: 'Artículos',
        }
    },
    // Gallery
    gallery: {
        menu: {
            chef: 'Chef Drew',
            press: 'Prensa',
            contact: 'Contáctanos',
        },
        page: {
            title: 'Galería',
        }
    },
    // Contact Us
    contact: {
        header: 'Nosotros - Contáctanos',
        menu: {
            chef: 'Chef Drew',
            press: 'Prensa',
            gallery: 'Galería'
        },
        form: {
            title: '!Agradecemos tus comentarios que nos hacen crecer y mejorar para ti!',
            name: 'Nombre',
            email: 'Correo',
            phone: 'Teléfono',
            message: 'Mensaje',
            send: 'Enviar mensaje',
            footer: '*Todos los campos son necesarios. <br /><br />Este formulario NO es para reservaciones, para reservar click <a style="color: white;" href="?page=reservations">aquí</a>.<br /><br />Una vez que recibamos su mensaje, responderemos tan pronto sea posible.<br /><br />Internados y trabajos de tiempo completo envía tu CV a <a style="color: white;" href="mailto:paulina@deckmans.com">paulina@deckmans.com</a>',
            success: 'Su mensaje fue enviado.',
            failed: 'Su mensaje no pudo ser enviado. Intente más tarde.'
        }
    },
    // Recommendations
    recommendations: {
        header: 'Nosotros - REcomendaciones',
        menu: {
            chef: 'Chef Drew',
            press: 'Prensa',
            gallery: 'Galería'
        },
        page: {
            title: 'Recomendaciones',
            subtitle: 'Deckman’s más que un concepto culinario, es una experiencia multisensorial',
            text: 'Siendo un restaurante diseñado totalmente al aire libre, estamos 100% en contacto con la naturaleza y\n' +
                'expuesto a nuestro entorno, hemos enlistado algunas sugerencias para hacer su visita mucho más\n' +
                'integrada al contexto:',
            bullets: [
                'Sugerimos traer consigo una chamarra ligera, inclusive en verano ya que nos encontramos en una franja mediterránea donde en el atardecer llegamos a tener bajas temperaturas.',
                'Si no viene aquí conduciendo, le sugerimos programar un transporte privado con un proveedor local.',
                'No contamos con sistema de transporte público regular en nuestra área.',
                'Sugerimos zapato cerrado/cómodo.',
                'Revisar el clima de su fecha reservada.',
                'El código de vestimenta sugerido es casual.',
            ]
        }
    },
    // Reservations
    reservations: {
        header: 'Reservaciones',
        menu: {
            gift_certificates: 'Certificados de Regalo',
            about_us: 'Nosotros',
            recommendations: 'Recomendaciones',
        },
        page: {
            title: 'Reservaciones',
            subtitle: '',
            text: 'Para reservaciones en linea de hasta 6 personas, la puede realizar a traves de OpenTable',
            text_2: 'Se recomienda hacer una sola reservación por grupo en caso de que se detecten múltiples\n' +
                'reservaciones para los mismos grupos serán automáticamente canceladas por el sistema.',
            text_3: 'Para reservaciones directas de hasta 5 personas, contáctenos por Whatsapp',
            text_4: 'Para grupos de 6 o más personas llame directamente al restaurante',
            bullets: [
                'Es altamente recomendado reservar por lo menos con 2 a 3 semanas de\n' +
                'anticipación.',
                'Atención diaria de 1:00 pm - 7:30 pm (UTC-8) Cerrado Martes y Miércoles.',
                'Aceptamos pagos en efectivo, Visa, Mastercard y American Express.'
            ]
        }
    }
}

export default es