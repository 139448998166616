import React from 'react'
import Logo from '../images/LOGO WEBSITE.png'
import BackgroundHome from '../images/backgrounds/Chef-Drew.jpg'
import BackgroundAbout from '../images/Q04Sc43o.jpeg'
import BackgroundChef from '../images/backgrounds/Chef-Drew.jpg'
import BackgroundGallery from '../images/h1.jpg'
import BackgroundPress from '../images/5iwvapgY.jpeg'
import BackgroundContact from '../images/qemyYeYc.jpeg'
import BackgroundRecommendations from '../images/backgrounds/7xN_f-BM.jpeg'
import BackgroundReservations from '../images/backgrounds/Reservations.jpg'
import MexFlag from '../images/MEX.png'
import UsaFlag from '../images/USA.png'
import MichelinGreenStar from '../images/michellin2024/MichelinGreenStar.png'

const Header = props => {
    const { setLang, page } = props
    const _setLang = (lang) => {
        setLang(lang)
        localStorage.setItem('deckmans_website_lang', lang)
    }
    const getBackground = (page) => {
        switch (page) {
            case 'home':
                return BackgroundHome
            case 'about':
                return BackgroundAbout
            case 'chef':
                return BackgroundChef
            case 'press':
                return BackgroundPress
            case 'gallery':
                return BackgroundGallery
            case 'contact':
                return BackgroundContact
            case 'recommendations':
                return BackgroundRecommendations
            case 'reservations':
                return BackgroundReservations
            default:
                return BackgroundHome
        }
    }
    return (
        <div className="container" style={{
            backgroundImage: `url(${getBackground(page)})`,
            backgroundPosition: 'top center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: '700px'
        }}>
            <header className="d-flex flex-wrap justify-content-center pb-3 mb-4">
                <div className="row">
                    <div className="col-7 col-md-4">
                        <div className="position-relative">
                            <a href="/"><img src={Logo} className='w-100'  alt='Logo' /></a>
                            <div className="position-absolute bottom-0" style={{ left: '42%', width: '15%', bottom: 10 }}>
                                <a href="https://guide.michelin.com/en/baja-california/valle-de-guadalupe_7770290/restaurant/deckman-s-en-el-mogor" target='_blank'>
                                    <img src={MichelinGreenStar} className='w-100' />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-md-6 d-flex justify-content-end">
                        <ul className="nav nav-pills my-5 ps-4 pe-2 py-3 bg-deckmans-green-75" style={{ height: '50px' }}>
                            <li className="nav-item ">
                                <a
                                    className='text-white text-decoration-none fw-bold'
                                    href="#"
                                    onClick={() => _setLang('en') }
                                >
                                    EN
                                    <img src={UsaFlag} className='w-75 d-none' alt='USA Flag' />
                                </a>
                            </li>
                            <li className="nav-item mx-4">
                                <a
                                    className='text-white text-decoration-none fw-bold'
                                    href="#"
                                    onClick={() => _setLang('es') }
                                >
                                    ES
                                    <img src={MexFlag} className='w-75 d-none' alt='Mex Flag' />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header