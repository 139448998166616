import React, {useEffect, useState} from 'react'
import '../css/app.css'
import { t } from '../../src/utils/translate/translate'
import MexFlag from '../images/MEX.png'
import UsaFlag from '../images/USA.png'

const Reservations = () => {
    useEffect(() => {
        const openTableScript = document.createElement("script")
        const container = document.getElementById("open-table-container")
        openTableScript.src = "https://www.opentable.com.mx/widget/reservation/loader?rid=442756&domain=commx&type=standard&theme=tall&lang=en&overlay=false&iframe=true"
        openTableScript.async = true

        // ***** TRICK by ChatGPT3 *****
        // Check if the script is already loaded
        if (!container.querySelector('script')) {
            container.appendChild(openTableScript)
        }

        return () => {
            // Cleanup function to remove the script when the component unmounts
            container.removeChild(openTableScript)
        }
    }, [])
    useEffect(() => {
        const pageElement = document.getElementById('page');
        if (pageElement) {
            pageElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [])
    return (
        <div id="reservation-container" className="container">
            <div className="row my-2">
                <div className="col-sm py-5 px-0 text-center bg-info menu_box menu_box_2">
                    <span className="py-2 my-5 bg-deckmans-green-75 text-white fs-4 d-block w-100">
                        <a href="?page=about" className='text-white text-decoration-none'>
                            { t('reservations.menu.gift_certificates') }
                        </a>
                    </span>
                </div>
                <div className="col-sm py-5 px-0 text-center bg-info menu_box menu_box_3">
                    <span className="py-2 my-5 bg-deckmans-green-75 text-white fs-4 d-block w-100">
                        <a href="?page=press" className='text-white text-decoration-none'>
                            { t('reservations.menu.about_us') }
                        </a>
                    </span>
                </div>
                <div className="col-sm py-5 px-0 text-center bg-info menu_box menu_box_5">
                    <span className="py-2 my-5 bg-deckmans-green-75 bg-opacity-25 text-white fs-4 d-block w-100">
                        <a href="?page=gallery" className='text-white text-decoration-none'>
                            { t('reservations.menu.recommendations') }
                        </a>
                    </span>
                </div>
            </div>
            <div id="page" className="row my-2 font-text-normal">
                <div className="col">
                    <h5>{ t('reservations.page.text') }</h5>
                </div>
            </div>
            <div className="row my-2">
                <div className="col">
                    <div id="open-table-container" className="mx-auto text-center"></div>
                </div>
            </div>
            <div className="row my-2">
                <div className="col px-3">
                    <p className="font-text-normal"
                       dangerouslySetInnerHTML={{
                        __html: t('reservations.page.text_2')
                    }}></p>
                    <p className="font-text-normal"
                       dangerouslySetInnerHTML={{
                           __html: t('reservations.page.text_3')
                       }}></p>
                    <p className="ps-5">
                        {/* Whatsapp */}
                        <a href="https://api.whatsapp.com/send?phone=+16197214820&text=Hello, Need a reservation for less than 5" target="_blank" rel="noreferrer"  className="text-black text-decoration-none">
                            <img src={UsaFlag} className='w-flag-25p me-2' alt='USA Flag' />
                            EE.UU. - 619 721 4820
                        </a> <br/>
                        <a href="https://api.whatsapp.com/send?phone=+526461883960&text=Hola, necesito reservacion para menos de 5" target="_blank" rel="noreferrer" className="text-black text-decoration-none">
                            <img src={MexFlag} className='w-flag-25p me-2' alt='MEX Flag' />
                            México - 646 188 3960
                        </a>
                    </p>
                    <p className="font-text-normal"
                       dangerouslySetInnerHTML={{
                           __html: t('reservations.page.text_4')
                       }}></p>
                    <p className="ps-5">
                        {/* Phone */}
                        <a href="tel:+16197214820" className="text-black text-decoration-none">
                            <img src={UsaFlag} className='w-flag-25p me-2' alt='USA Flag' />
                            EE.UU. - 619 721 4820
                        </a> <br/>
                        <a href="tel:+526461883960" className="text-black text-decoration-none">
                            <img src={MexFlag} className='w-flag-25p me-2' alt='MEX Flag' />
                            México - 646 188 3960
                        </a>
                    </p>
                    <ul>{ t('reservations.page.bullets').map(bullet => <li className='font-text-normal py-3'>{ bullet }</li>) }</ul>
                </div>
            </div>

        </div>
    )
}

export default Reservations